.CreateOderContainer {
 margin: 2%;
}

.CreateOderContainer h3 {
 margin: 2%;
}

.CreateOderContainer hr {
 border-color: black;
 border-top-width: 3px;
}

.modalBtnWrapper {
 width: 30%;
 display: flex;
 flex-direction: row;
 align-content: space-between;
 justify-content: space-around;
}