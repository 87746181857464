.configuration_Container {
 width: 100;
 overflow: auto;
}

.orderDataTbl {
 /* overflow: scroll; */
 /* min-height: 200px; */
}

.vendorTableContainer {
 margin: 1%;
 margin-bottom: 5%;
 margin-top: 5%;
}

.modal_vendor_table {
 max-height: 180px;
 overflow: scroll;
}

.custom-control-label {
 position: unset !important;
 margin-left: 0px !important;
}